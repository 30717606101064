import React, { useRef, useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { getCapitalizedName } from "../../helper/commonUtils";

const DropDown = props => {

    const {
        id,
        dataTestid,
        label,
        placeholder,
        getDisplayValue,
        value: activeItem,
        listData,
        onValueChange,
        disabled,
        capitalizeOption = false,
        classes = {}
    } = props;
    const dropdownToggleRef = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        dropdownToggleRef.current.click();
    }

    useEffect(() => {
        // Reset the scroll position to the top when the dropdown is opened
        if (isOpen && !activeItem) {
            dropdownRef.current.scrollTop = 0;
        }
        else if (isOpen && activeItem) {
            const selectedIndex = listData.findIndex(item => item.id === activeItem);
            if (selectedIndex !== -1) {
                const itemElement = dropdownRef.current?.children[selectedIndex];
                itemElement?.scrollIntoView({ block: 'start' });
            }
        }
    }, [isOpen]);

    return (
        <div className='vve-dropdown' onClick={toggleDropdown} data-testid={dataTestid}>
            <Dropdown.Header>{label}</Dropdown.Header>
            <Dropdown className={`vve-select  ${disabled ? 'disabled' : ''}`} data-bs-theme="light" onToggle={(isOpen) => setIsOpen(isOpen)}>
                <Dropdown.Toggle id={id} data-testid={props.dataTestid + "-toggle"} variant="secondary" ref={dropdownToggleRef} disabled={disabled}>
                    {getDisplayValue()}
                </Dropdown.Toggle>

                <Dropdown.Menu ref={dropdownRef} className={classes.dropdownMenu}>
                    {placeholder && (
                        <Dropdown.Item active={!activeItem} onClick={() => onValueChange("")}>
                            {placeholder}
                        </Dropdown.Item>
                    )}
                    {listData.map((val, index) => {
                        const itemIndex = `dropdown-option-item-${index}`;
                        return <Dropdown.Item
                                key={itemIndex}
                                active={activeItem === val.id}
                                onClick={() => onValueChange(val.id)}
                                data-testid={props.dataTestid + "-option-" + index}>
                                    {capitalizeOption ? getCapitalizedName(val.name) : val.name}
                            </Dropdown.Item>
                        }
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default DropDown;
